import React from "react"
import Layout from "../components/layout"
import { AllProjects } from "../components/allprojects"
import { projects as allProjects } from "./../data"

const IndexPage = () => {
  var title = <h1 className="home-title">Design. Code. Ship. Iterate.</h1>
  var desc = (
    <React.Fragment>
      <p style={{ marginBottom: 0 }}>Hello! I'm EB.</p>
      <p style={{ marginBottom: 0 }}>Front-end developer & UX designer.</p>
      <p style={{ marginBottom: 0 }}>My works are below.</p>
    </React.Fragment>
  )

  if (typeof window !== `undefined` && window.innerWidth < 600) {
    title = (
      <h1 className="home-title">
        Design. Code.
        <br />
        Ship. Iterate.
      </h1>
    )

    desc = (
      <React.Fragment>
        <p style={{ marginBottom: 0 }}>
          Hello! I'm EB. Front-end developer & UX designer.
        </p>
      </React.Fragment>
    )
  }
  return (
    <Layout title="Home">
      <div className="page-title">
        {title}
        {desc}
        <div className="arrow" />
      </div>
      <div className="section-bottom">
        <AllProjects projects={allProjects} />
      </div>
    </Layout>
  )
}

export default IndexPage
