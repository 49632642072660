import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Card, CardBody, Row, Col } from "reactstrap"
import { tags, interests } from "./../data"
import VisibilitySensor from "react-visibility-sensor"
import LazyLoad from "react-lazyload"
import { InternalLink, ExternalLink } from "./parts"
import { RiInformationLine, RiPenNibLine } from "react-icons/ri"

const sortByOptions = [
  {
    value: "importance",
    title: "By importance",
  },
  {
    value: "date",
    title: "By recency",
  },
]

export const AllProjects = ({ projects }) => {
  const [selected, setSelected] = useState("all")
  const [locked, setLocked] = useState(true)
  const [sort] = useState(sortByOptions[0].value)

  useEffect(() => {
    const locked =
      typeof window !== "undefined" &&
      sessionStorage.getItem("unlock_me") !== "hi"
    setLocked(locked)
  }, [])

  const availableProjects = locked
    ? projects.filter(p => p.locked !== locked)
    : projects

  const filteredProjects = availableProjects
    .filter(p => (selected === "all" ? true : p.tags.indexOf(selected) >= 0))
    .sort((a, b) => b[sort] - a[sort])

  const isSelected = t => t === selected

  return (
    <div>
      <div className="text-center">
        {Object.keys(tags).map((t, i) => {
          let className = `filters-tag`
          let tick = null

          if (isSelected(t)) {
            className += " is-active"
            tick = <img src="/images/tick.svg" alt="tick" />
          }

          return (
            <span
              key={t}
              className={className}
              onClick={e => {
                if (typeof window !== `undefined`) {
                  window.scrollTo(0, e.target.offsetTop - 120)
                }
                setSelected(t)
              }}
              onKeyDown={() => setSelected(t)}
              role="button"
              tabIndex={i + 1}
            >
              {tick}
              {tags[t].title}
            </span>
          )
        })}
      </div>
      <div className="text-center">
        <span className="filters-count">
          {filteredProjects.length} / {availableProjects.length} projects
          {selected !== "all" ? (
            <span
              role="button"
              onClick={() => setSelected("all")}
              onKeyDown={() => setSelected("all")}
              tabIndex={0}
            >
              Show all
            </span>
          ) : null}
        </span>
      </div>
      <div className="section" style={{ paddingTop: 24 }}>
        <div className="projects-list">
          <Row key={filteredProjects.length}>
            {filteredProjects.map(p => {
              return (
                <Col lg={4} md={6} sm={12} xs={12} key={p.image}>
                  <Link to={p.url}>
                    <VisibilitySensor
                      offset={{
                        top:
                          (typeof window !== `undefined` &&
                            window.innerWidth) <= 600
                            ? 0
                            : 120,
                      }}
                    >
                      {({ isVisible }) => (
                        <Card className="project-card">
                          <CardBody>
                            <LazyLoad height={300} offset={500}>
                              <img
                                className={
                                  "cover-img" + (isVisible ? " color" : "")
                                }
                                src={`/images/projects/cover/${p.image}`}
                                alt="cover"
                              />
                            </LazyLoad>
                            <h4>{p.title}</h4>
                            <p>{p.subtitle}</p>
                          </CardBody>
                        </Card>
                      )}
                    </VisibilitySensor>
                  </Link>
                </Col>
              )
            })}
          </Row>
          {filteredProjects.length === 0 ? (
            <Row>
              <Col className="text-center">
                <h5>No projects to show. Please alter the filters.</h5>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
      <Row>
        <Col lg={4} md={6} xs={12} sm={12} key="about">
          <InternalLink
            to="/about"
            label={
              <span>
                <RiInformationLine /> About me
              </span>
            }
            block
          />
        </Col>
        <Col lg={4} md={6} xs={12} sm={12} key="writing">
          <ExternalLink
            to="/about"
            label={
              <span>
                <RiPenNibLine /> Writing
              </span>
            }
            block
          />
        </Col>
        {interests.map(i => (
          <Col lg={4} md={6} xs={12} sm={12} key={i.url}>
            {i.isExternal ? (
              <ExternalLink
                href={i.url}
                label={
                  <span>
                    {i.icon} {i.title}
                  </span>
                }
                block
              />
            ) : (
              <InternalLink
                to={i.url}
                label={
                  <span>
                    {i.icon} {i.title}
                  </span>
                }
                block
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  )
}
